import * as React from 'react'

import { Layout } from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <main className="flex flex-col justify-center items-center">
      <title>Not found</title>
      <h1 className="text-5xl text-center text-white">Page not found</h1>
    </main>
  </Layout>
)

export default NotFoundPage
